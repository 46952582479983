

#booking {
	font-family: 'Lato', sans-serif;
}

.booking-form {
	background: #c5bebe;
	max-width: 642px;
	width: 100%;
	margin: auto;
}

.booking-form .form-header {
	background-image: url('../assets/img/psych.jpg');
	background-size: cover;
	background-position: center;
	padding: 85px 25px 25px;
	position: relative;
	z-index: 20;
}

.booking-form .form-header::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(204, 196, 196, 0.8);
	z-index: -1;
}

.booking-form>form {
	padding: 30px;
}

.booking-form .form-header h2 {
	font-family: 'Medula One', cursive;
	margin-top: 0;
	margin-bottom: 15px;
	color: rgb(20, 19, 19);
	font-size: 60px;
	text-transform: capitalize;
}

.booking-form .form-header p {
	color: #fff;
	font-size: 18px;
}

.booking-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.booking-form .form-control {
	background-color: transparent;
	height: 45px;
	padding: 0px 20px;
	color: #fff;
	border: 2px solid rgba(255, 255, 255, 0.15);
	font-size: 16px;
	font-weight: 700;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 40px;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

